<script setup lang="ts">
const reload = () => {
  reloadNuxtApp({
    force: true,
    path: '/',
    persistState: false,
  });
};

const showReloadButton = ref(false);

onMounted(() => {
  setTimeout(() => {
    showReloadButton.value = true;
  }, 10000);
});
</script>
<template>
  <div class="h-full w-full">
    <main class="grid min-h-full place-items-center bg-white">
      <div class="text-center flex justify-center items-center flex-col">
        <nuxt-img src="/images/maintenance/maintenance.png" alt="Maintenance" class="w-[400px] mb-10" />
        <h1 class="text-balance text-4xl font-semibold tracking-tight text-primary-500">{{ $t('maintenance.title') }}</h1>
        <i18n-t tag="p" keypath="maintenance.description" class="mt-4 text-balance text-lg text-gray-500">
          <template #phone>
            <a href="tel:0743394043" class="text-primary-500 hover:text-primary-700 cursor-pointer underline">07 43 39 40 43</a>
          </template>
          <template #email>
            <a href="mailto:a.dury@nrjx.tech" class="text-primary-500 hover:text-primary-700 cursor-pointer underline">a.dury@nrjx.tech</a>
          </template>
        </i18n-t>
        <p class="mt-2 text-balance text-gray-500">{{ $t('maintenance.description_2') }}</p>
        <ui-button v-if="showReloadButton" class="w-[200px] mt-9" @click="reload">{{ $t('global.reload_page') }}</ui-button>
      </div>
    </main>
  </div>
</template>
